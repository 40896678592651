














































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { GetPalmDetail } from "@/request/research";
@Component({})
export default class Name extends Vue {
  private id: any = "";
  private detail: any = {
    l_palm_image: [],
    r_palm_image: [],
    专家建议: {
      按诊断分: {
        中医疾病: [],
        中医证型: [],
        西医疾病: [],
      },
    },
  };
  private getText(key: any, type: any) {
    let str: any = "";
    const d = this.detail[key][type];
    console.log(key)
    console.log(type)
    console.log(d);
    const keys: any = {
      掌型: [
        {
          name: "掌型",
          label: "掌型",
          type: "多选",
        },
      ],
      掌色: [
        {
          name: "掌色",
          label: "掌色",
          type: "多选",
        },
      ],
      掌纹: [
        {
          name: "1线",
          label: "1线",
          type: "多选",
        },
        {
          name: "2线",
          label: "2线",
          type: "多选",
        },
        {
          name: "3线",
          label: "3线",
          type: "多选",
        },
        {
          name: "4线",
          label: "4线",
          type: "多选",
        },
        {
          name: "5线",
          label: "5线",
          type: "单选",
        },
        {
          name: "6线",
          label: "6线",
          type: "多选",
        },
        {
          name: "7线",
          label: "7线",
          type: "多选",
        },
        {
          name: "8线",
          label: "8线",
          type: "单选",
        },
        {
          name: "9线",
          label: "9线",
          type: "单选",
        },
        {
          name: "10线",
          label: "10线",
          type: "单选",
        },
        {
          name: "11线",
          label: "11线",
          type: "多选",
        },
        {
          name: "12线",
          label: "12线",
          type: "单选",
        },
        {
          name: "13线",
          label: "13线",
          type: "多选",
        },
        {
          name: "14线",
          label: "14线",
          type: "单选",
        },
        {
          name: "天庭十字纹",
          label: "天庭十字纹",
          type: "单选",
        },
        {
          name: "艮震线",
          label: "艮震线",
          type: "单选",
        },
        {
          name: "咽区",
          label: "咽区",
          type: "多选",
        },
        {
          name: "三星高照",
          label: "三星高照",
          type: "单选",
        },
        {
          name: "乳腺纹",
          label: "乳腺纹",
          type: "单选",
        },
        {
          name: "血脂邱",
          label: "血脂邱",
          type: "单选",
        },
        {
          name: "孔子眼",
          label: "孔子眼",
          type: "单选",
        },
        {
          name: "暴怒指",
          label: "暴怒指",
          type: "单选",
        },
      ],
      手掌分区: [
        {
          name: "巽宫",
          label: "巽宫",
          type: "多选",
        },
        {
          name: "离宫",
          label: "离宫",
          type: "多选",
        },
        {
          name: "坤宫",
          label: "坤宫",
          type: "多选",
        },
        {
          name: "震宫",
          label: "震宫",
          type: "多选",
        },
        {
          name: "明堂",
          label: "明堂",
          type: "多选",
        },
        {
          name: "兑宫",
          label: "兑宫",
          type: "多选",
        },
        {
          name: "艮宫",
          label: "艮宫",
          type: "多选",
        },
        {
          name: "坎宫",
          label: "坎宫",
          type: "多选",
        },
        {
          name: "乾宫",
          label: "乾宫",
          type: "多选",
        },
      ],
    };
    if (type == "掌型" || type == "掌色") {
      keys[type].forEach((element: any) => {
        str += d[element.name].join("、");
      });
    } else {
      keys[type].forEach((element: any) => {
        if (element.type == "单选" && d[element.name]) {
          str += element.name + "（" + d[element.name] + "）；";
        } else if (
          element.type == "多选" &&
          d[element.name] &&
          d[element.name].length > 0
        ) {
          const text: any = d[element.name].join("、");
          console.log(text);
          str += element.name + "（" + text + "）；";
        }
      });
    }
    return str ? type + "：" + str : type + "：" + "/";
  }
  private getDetail() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        palm_id: this.id,
      },
    };
    GetPalmDetail(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.detail = data;
      })
      .catch(() => {
        loading.close();
      });
  }
  private mounted() {
    this.id = this.$route.query.id;
    this.getDetail();
  }
}
